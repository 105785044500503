




























import { Component, Vue } from 'vue-property-decorator';
import { Getter, namespace } from 'vuex-class';
import Community from '@/models/graphql/Community';
import ButtonComponent from '@/components/ButtonComponent.vue';
import AuthPageGuard from '@/guards/AuthPageGuard';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import CommunityFeature from '@/models/graphql/CommunityFeature';

const authenticationStore = namespace('AuthenticationStore');

@Component({
  components: { ButtonComponent },
  beforeRouteEnter: AuthPageGuard.beforeEnter,
})
export default class SignInNoSignUp extends Vue {
  @Getter
  private readonly community!: Community;

  @Getter
  private readonly featureByKey!: (key: FeatureKeys) => CommunityFeature;

  @authenticationStore.State
  private readonly authEmail!: string | null;

  get registrationUrl(): string {
    return !!this.community
    && this.community.organizer
    && this.community.organizer.registrationWebsite
    && !!this.featureByKey(FeatureKeys.COMMUNITY_EXTERNAL_REGISTRATION_FEATURE)
    && this.featureByKey(FeatureKeys.COMMUNITY_EXTERNAL_REGISTRATION_FEATURE).enabled
      ? this.community.organizer.registrationWebsite
      : '';
  }

  private get email(): string {
    if (this.authEmail) {
      return this.authEmail;
    }
    return `${this.$t('app.sign-in.no-sign-up.your-email')}`;
  }

  private onClickRegistration(): void {
    window.open(this.registrationUrl, '_blank');
  }

  private onClickGoBack(): void {
    this.$router.push({ name: 'sign-in' });
  }
}
